import React, { Component, lazy } from "react";

const OrganizationPage = lazy(() => import("../../../../pages/Organization"));


const AssetDetailPage = lazy(() => import("../../../../pages/AssetDetail"));
const AssetsPage = lazy(() => import("../../../../pages/Assets"));
const AssetDescriptionPage = lazy(
    () => import("../../../../pages/AssetsDescription")
);
const AssetTableViewPage = lazy(() => import("../../../../pages/AssetsTable"));

const MintedAssetDescriptionPage = lazy(
    () => import("../../../../pages/MintedAssetsDescription")
);
const MintedAssetsPage = lazy(() => import("../../../../pages/MintedAssets"));
const MintedAssetDetailPage = lazy(
    () => import("../../../../pages/MintedAssetDetail")
);
const MintedAssetTablePage = lazy(
    () => import("../../../../pages/MintedAssetsTable")
);

const ListedAssetsDescriptionPage = lazy(
    () => import("../../../../pages/ListedAssetsDescription")
);
const ListedAssetsPage = lazy(() => import("../../../../pages/ListedAssets"));
const ListedAssetDetailPage = lazy(
    () => import("../../../../pages/ListedAssetDetail")
);
const ListedAssetTablePage = lazy(
    () => import("../../../../pages/ListedAssetsTable")
);

const SoldAssetDescriptionPage = lazy(
    () => import("../../../../pages/SoldAssetDescription")
);
const SoldAssetsPage = lazy(() => import("../../../../pages/SoldAssets"));
const SoldAssetDetailPage = lazy(
    () => import("../../../../pages/SoldAssetDetail")
);
const SoldAssetTablePage = lazy(
    () => import("../../../../pages/SoldAssetsTable")
);

const CreateCollectablePage = lazy(
    () => import("../../../../pages/CreateCollectables")
);

const CreatedAssetTableDetailPage = lazy(()=>import("../../../../pages/AssetTableDetail"));

const ListedAssetTableDetailPage = lazy(()=>import("../../../../pages/ListedAssetTableDetail"));

const MintedAssetTableDetailPage = lazy(()=>import("../../../../pages/MintedAssetTableDetail"));

const SoldAssetTableDetailPage = lazy(()=>import("../../../../pages/SoldAssetTableDetail"));

const CreateAssetDescriptionPage = lazy(()=>import("../../../../pages/CreateAssetDescription"));


// const MyAssetsPage = lazy(() => import("../../../../pages/MyAssets"));
// const MyAssetDetailPage = lazy(() => import("../../../../pages/MyAssetDetail"));

// const MyWalletPage = lazy(() => import("../../../../pages/MyWallet"));
const AnalyticsPage = lazy(() => import("../../../../pages/Analytics"));

export const routes = [
    { path: "/organization", Component: OrganizationPage },
    { path: "/asset-description", Component: AssetDescriptionPage },
    { path: "/assets-list", Component: AssetsPage },
    { path: "/table-view", Component: AssetTableViewPage },
    { path: "/assets-list/asset", Component: AssetDetailPage },
    { path: "/mint-asset-description", Component: MintedAssetDescriptionPage },
    { path: "/mint-assets", Component: MintedAssetsPage },
    { path: "/mint-assets/asset", Component: MintedAssetDetailPage },
    { path: "/mint-assets-table", Component: MintedAssetTablePage },
    { path: "/listed-asset-description", Component: ListedAssetsDescriptionPage },
    { path: "/listed-assets", Component: ListedAssetsPage },
    { path: "/listed-assets/asset", Component: ListedAssetDetailPage },
    { path: "/listed-assets-table", Component: ListedAssetTablePage },
    { path: "/sold-asset-description", Component: SoldAssetDescriptionPage },
    { path: "/sold-assets", Component: SoldAssetsPage },
    { path: "/sold-assets/asset", Component: SoldAssetDetailPage },
    { path: "/sold-table-view", Component: SoldAssetTablePage },
    { path: "/create-assets", Component: CreateCollectablePage },
    { path: "/listed-assets-table/asset", Component: ListedAssetTableDetailPage },
    { path: "/mint-assets-table/asset", Component:  MintedAssetTableDetailPage},
    { path: "/sold-table-view/asset", Component:  SoldAssetTableDetailPage},
    { path: "/table-view/asset", Component: CreatedAssetTableDetailPage },
    { path: "/analytics", Component: AnalyticsPage },
    { path: "/create-asset-description", Component: CreateAssetDescriptionPage },
    // { path: "/", Component: AssetsPage },
    
    
    // { path: "/my-assets", Component: MyAssetsPage },
    // { path: "/my-assets/asset", Component: MyAssetDetailPage },
    // { path: "/my-wallet", Component: MyWalletPage },
    
];
