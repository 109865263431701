//@ts-nocheck
import React, { Fragment, useEffect, useState } from "react";
import Loader from "./component/common/loader/loader";
import Header from "./component/common/header/header";
import HeaderSM from "./component/common/header/headerSM";
import Sidebar from "./component/common/sidebar/sidebar";
import SidebarSM from "./component/common/sidebar/sidebarsm";
import Rightsidebar from "./component/common/sidebar/rightsidebar";
import Footer from "./component/common/footer/footer";
import { useGetNavitem } from "./appProvider";
import {useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { AuthService } from "../../../services";
// import { useAuth } from "../../../services/auth-service/auth.provider";
import { VApplication } from "../../../application";

import {
    useViewport,
    ViewportProvider,
} from "@vlinder-web/viewport-provider-react";

const loginService = VApplication.getInstance().getSync(
    "services.AuthService"
) as AuthService;

const MENU_ITEMS = [
    {
        eventKey: "organization",
        title: "",
        icon: <i class="icon-home"></i>,
        path: "/organization",
        type: "sub",
        active: false,
        bookmark: false,
        children: [{ title: "Organization", type: "sub" }],
    },
    {
        eventKey: "analytics",
        title: "",
        icon: <i className="icon-bar-chart-alt"></i>,
        path: "/analytics",
        type: "sub",
        active: false,
        children: [{ title: "Analytics", type: "sub" }],
    },
    {
        eventKey: "Created Assets",
        title: "",
        icon:<i className="pe-7s-diamond"></i> ,
        path: "/asset-description",
        type: "sub",
        active: false,
        children: [
            { title: "Created Assets", type: "sub" },
            {
                title: "Created Assets",
                type: "link",
                path: `/assets-list`,
            },
            {
                title: "Created assets Table",
                type: "link",
                path: `/table-view`,
            },
        ],
    },
    {
        eventKey: "Minted Assets",
        title: "",
        icon: <i className="icofont icofont-connection"></i>,
        path: "/mint-asset-description",
        type: "sub",
        active: false,
        children: [
            { title: "Minted Assets", type: "sub" },
            {
                title: "Minted Assets",
                type: "link",
                path: `/mint-assets`,
            },
            {
                title: "Minted Assets Table",
                type: "link",
                path: `/mint-assets-table`,
            },
        ],
    },
    {
        eventKey: "Listed Assets",
        title: "",
        icon: <i className= "icon-list"></i>,
        path: "/listed-asset-description",
        type: "sub",
        active: false,
        children: [
            { title: "Listed Assets", type: "sub" },
            {
                title: "Listed Assets",
                type: "link",
                path: `/listed-assets`,
            },
            {
                title: "Listed Assets Table",
                type: "link",
                path: `/listed-assets-table`,
            },
        ],
    },
    {
        eventKey: "Sold Assets",
        title: "",
        icon: <i className= "icon-bag"></i>,
        path: "/sold-asset-description",
        type: "sub",
        active: false,
        children: [
            { title: "Sold Assets", type: "sub" },
            {
                title: "Sold Assets",
                type: "link",
                path: `/sold-assets`,
            },
            {
                title: "Sold Assets Table",
                type: "link",
                path: `/sold-table-view`,
            },
        ],
    },
    {
        eventKey: "collectibleForm",
        title: "",
        icon:  <i className= "icon-pencil-alt"></i>,
        path: "/create-asset-description",
        type: "sub",
        active: false,
        children: [
            { title: "Assets", type: "sub" },
            {
                title: "New Assets",
                type: "link",
                path: `/create-assets`,
            },
        ],
    },
];

const getAuthSerivice = () => {
    const vapp = VApplication.getInstance();
    const [userName, setUserName] = useState<string>();
    const authSerivice = vapp.getSync(
        "services.AuthService"
    ) as AuthService;
    return authSerivice;
};

const App = ({ children }) => {
    const { bp, width } = useViewport();
    const [userName, setUserName] = useState<string>();
    // const {getUserProfile, signOut} = useAuth();
    const history = useHistory();

    useEffect(()=>{
        loginService.getUserProfile().then(res=>{
            console.log(`user profile in bugsbunny`, res);
            setUserName(res?.fullName);
        })
    },[])

    const onLogoutClick =() => {
        console.log('logout button pressed');
        loginService.signOut();
        window.location.reload();
    }

    // let _sideBarItems = [];
    // Array.isArray(MENU_ITEMS) &&
    //   MENU_ITEMS.map((item) => {
    //     let _tempNav;
    //     context?.payload?.navItems?.map((ele) => {
    //       if (item?.eventKey === ele?.key && ele?.enable) {
    //         _sideBarItems.push({
    //           ...item,
    //         });
    //       }
    //     });
    //   });

    const getSidebar = () => {
        if (bp === "xs" || bp === "sm") {
            return <SidebarSM menuItems={MENU_ITEMS} />;
        } else {
            return <Sidebar menuItems={MENU_ITEMS} />;
        }
    };

    const getHeader = () => {
        if (bp === "xs" || bp == "sm") {
            return <HeaderSM menuItems={MENU_ITEMS} searchbar={false} userName={userName} onLogoutClick={onLogoutClick}/>;
        } else {
            return <Header menuItems={MENU_ITEMS} searchbar={false} userName={userName} onLogoutClick={onLogoutClick}/>;
        }
    };
    return (
        <Fragment>
            <Loader />
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    {getHeader()}
                    {getSidebar()}
                    <Rightsidebar />
                    <div className="page-body">{children}</div>
                    <Footer />
                    {/* <ThemeCustomize/> */}
                </div>
            </div>
            <ToastContainer
                autoClose={2000}
                position="top-center"
                className="toast-container"
                toastClassName="dark-toast"
            />
        </Fragment>
    );
};

export default App;
