import gql from "graphql-tag";

export const GET_ORGANIZATION = gql`
    query Query {
        getMyOrganization {
            _id
            sameAs
            telephone
            description
            name
            email
            creator
            fullAddress
            logo
            createdAt
            updatedAt
            OrgType
            address {
                addressLine1
                addressLine2
                city
                state
                zipcode
                country
            }
        }
    }
`;

// query Query {
//   getMyOrganization {
//     _id
//     logo
//     name
//     description
//     fullAddress
//     phoneNo
//     website
//     email
//   }
// }
