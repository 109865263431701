import { EventBusModule } from "@vlinder-web/event-bus-module-react";
import { FSMFactoryModule } from "@vlinder-web/state-machine-factory-module-react";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { BaseApplication } from "./base/baseApplication";
import { UnitTest } from "./unit.test";
import {
    TrafficLightService,
    DeviceInfoService,
    DummyRestService,
    DummyGraphQLService,
    OrganizationService,
    AssetsService,
    AssetDescriptionService,
    CreatedAssetTableService,
    AssetDetailService,
    MintedAssetDescriptionService,
    MintedAssetsService,
    MintedAssetDetailService,
    MintedAssetTableService,
    ListedAssetDescriptionService,
    ListedAssetsService,
    ListedAssetDetailService,
    ListedAssetTableService,
    SoldAssetDescriptionService,
    SoldAssetsService,
    SoldAssetDetailService,
    SoldAssetTableService,
    CreateAssetService,
    CreateAssetDescriptionService,
    AuthService
    

} from '../services';

import { DEFAULT_APP_CONFIG } from "../config/default.config";

export class VApplication extends BaseApplication {
    private static instance: VApplication;

    private constructor(options: any = {}) {
        super(options);

        this.setupBindings();

        //Modules
        this.initModules();

        //Services
        this.initServices();
    }

    static getInstance(options: any = {}) {
        if (!this.instance) {
            this.instance = new VApplication(options);
        }
        return this.instance;
    }

    // @ts-ignore
    async start() {
        await this.initRunLevel();
        await this.unitTest();
    }

    setupBindings() {
        //------------->>>>>--------------//
        this.configure('services.DummyRestService', {
            baseURL: DEFAULT_APP_CONFIG?.servers.rest.dummyRestServer.baseURL,
        });
        //------------->>>>>--------------//
    }

    async initRunLevel() {
        //------------->>>>>--------------//
        const graphqlModule = this.getSync(
            'services.GraphqlServerModule',
        ) as GraphqlServerModule;
        if (graphqlModule) {
            type TClient = typeof DEFAULT_APP_CONFIG.servers.graphql[0];
            DEFAULT_APP_CONFIG.servers.graphql.forEach(
                async (client: TClient) => {
                    await graphqlModule.createClient(client.name, {
                        url: client.url,
                        getContext: this.getGraphQLContext.bind(this),
                    });
                },
            );
        }
        //------------->>>>>--------------//
    }

    async unitTest() {
        const ut = new UnitTest(VApplication);
        ut.run();
    }

    initModules() {
        this.service("services.FSMFactoryModule", FSMFactoryModule);
        this.service("services.EventBusModule", EventBusModule);
        this.service('services.GraphqlServerModule', GraphqlServerModule);
    }

    initServices() {
        this.service('services.AuthService', AuthService);
        this.service('services.TrafficLightService', TrafficLightService);
        this.service('services.DummyRestService', DummyRestService);
        this.service('services.DeviceInfoService', DeviceInfoService);
        this.service('services.DummyGraphQLService', DummyGraphQLService);
        this.service('services.OrganizationService', OrganizationService);
        this.service('services.AssetsService', AssetsService);
        this.service('services.AssetDescriptionService', AssetDescriptionService);
        this.service('services.CreatedAssetTableService', CreatedAssetTableService);
        this.service('services.AssetDetailService', AssetDetailService);
        this.service('services.MintedAssetDescriptionService', MintedAssetDescriptionService);
        this.service('services.MintedAssetsService', MintedAssetsService);
        this.service('services.MintedAssetDetailService', MintedAssetDetailService);
        this.service('services.MintedAssetTableService', MintedAssetTableService);
        this.service('services.ListedAssetDescriptionService', ListedAssetDescriptionService);
        this.service('services.ListedAssetsService', ListedAssetsService);
        this.service('services.ListedAssetDetailService', ListedAssetDetailService);
        this.service('services.ListedAssetTableService', ListedAssetTableService);
        this.service('services.SoldAssetDescriptionService', SoldAssetDescriptionService);
        this.service('services.SoldAssetsService', SoldAssetsService);
        this.service('services.SoldAssetDetailService', SoldAssetDetailService);
        this.service('services.SoldAssetTableService', SoldAssetTableService);
        this.service('services.CreateAssetDescriptionService', CreateAssetDescriptionService);
        this.service('services.CreateAssetService', CreateAssetService);
    }

    private getGraphQLContext() {
        const deviceInfoService = this.getSync(
            'services.DeviceInfoService',
        ) as DeviceInfoService;
        console.log('graphqlContext call');
        //FIXME:
        return {
            headers: {
                authentication: 'Iam your token',
                headers: {
                    ...{ ...deviceInfoService?.getDeviceInfo() },
                    ...{ ...deviceInfoService?.getAppInfo() },
                },
            },
        };
    }

}
